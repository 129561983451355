import "../styles/globals.css";

import type { NextPage } from "next";
import Link from "next/link";
import { PrismicProvider } from "@prismicio/react";
import { linkResolver } from "../../prismicio";
import Layout from "../components/layout/MainLayout";
import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { Open_Sans } from "@next/font/google";

const openSans = Open_Sans({
  display: "swap",
  subsets: ["latin"],
  weight: ["300", "400", "500", "700", "800"],
  variable: "--font-open-sans",
});

const App: NextPage = ({ Component, pageProps }: any) => {
  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.GTM_ID || "GTM-TWX2D75" });
  }, []);

  return (
    <>
      <Layout>
        <PrismicProvider
          linkResolver={linkResolver}
          internalLinkComponent={({ href, ...props }) => (
            <Link href={href} {...props} />
          )}
        >
          {/* eslint-disable-next-line react/no-unknown-property */}
          <style jsx global>
            {`
              :root {
                --font-open-sans: ${openSans.style.fontFamily};
              }
            `}
          </style>
          <Component {...pageProps} />
        </PrismicProvider>
      </Layout>
    </>
  );
};

export default App;
