import React, { createContext, useEffect, useState } from "react";

interface IMainLayoutContextProps {
  mobileMenuOpen: boolean;
  setMobileMenuOpen: () => void;
  currentPath: string;
}

export const MainLayoutContext = createContext({} as IMainLayoutContextProps);

const Layout: React.FC<React.PropsWithChildren> = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [currentPath, setCurrentPath] = useState<string>("");

  useEffect(() => {
    const body = document.body;

    if (mobileMenuOpen) {
      body.classList.add("overflow-hidden");
    } else {
      body.classList.remove("overflow-hidden");
    }
  }, [mobileMenuOpen]);

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, []);

  return (
    <MainLayoutContext.Provider
      value={{
        currentPath,
        mobileMenuOpen,
        setMobileMenuOpen: () => {
          setMobileMenuOpen((open: boolean) => !open);
        },
      }}
    >
      <div className="antialiased">{props.children}</div>
    </MainLayoutContext.Provider>
  );
};
export default Layout;
