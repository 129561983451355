import * as prismic from "@prismicio/client"; // client to query content
import { enableAutoPreviews } from "@prismicio/next"; // plugin for previews
import sm from "./sm.json";

export const endpoint = sm.apiEndpoint;
export const repositoryName = prismic.getRepositoryName(endpoint);

/* Update the Link Resolver to match your project's route structure,
   previews use this to find your docs */
export function linkResolver(doc) {
  let prefix = "";
  if (doc.lang === "en-GB") prefix = "/en";
  if (doc.url) {
    doc.url = doc.url.replace("/" + doc.lang, "");
  }

  if (doc.isBroken) {
    return "/pagina-niet-gevonden";
  }

  if (doc?.link_type === "Web") {
    return doc?.url.toString();
  }

  if (doc?.link_type === "Media") {
    return doc?.url.toString();
  }

  switch (doc.type) {
    case "pages":
      // console.log(`${prefix}${doc.url}`);
      if (doc.url) return `${prefix}${doc.url}`;
      return `${prefix}/${doc.uid}`;
    case "navigation":
      return null;
    default:
      return "/";
  }
}

export function isStructuredTextEmpty(data) {
  return (
    data &&
    data.filter((i) => i.url || i.oembed || i.text.trim() !== "").length === 0
  );
}

const routes = [
  {
    type: "pages",
    path: "/:uid",
  },
  {
    type: "pages",
    resolvers: {
      parent: "parent",
    },
    path: "/:parent/:uid",
  },
  {
    type: "pages",
    resolvers: {
      parent: "parent",
    },
    path: "/:lang?/:parent/:uid",
  },
];

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config {prismicNext.CreateClientConfig} - A configuration object to
 */
export async function createClient(config = {}) {
  const client = prismic.createClient(endpoint, {
    linkResolver: linkResolver,
    routes: routes,
    ...config,
  });

  enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  });

  return client;
}
